import POST_LOGIN_ACTION_TYPE from './post_login_action_type.js';
const POST_LOGIN_ACTION_ID_URL_PARAM = 'post_login_action_id';

import { del } from '../secure_fetch.js';
import {getService as service} from '../angular_helpers.js';
import UrlService from '../url_service.js';
import { checkBookmarks } from '../libraries.js';

/**
 * This class has methods for processing a "post login action's" data from the
 * server side.
 *
 * See the documentation for PostLoginActionCreatorService for more informaation
 * about "post login actions" and how they're meant to be used.
 *
 */
export default class PostLoginActionProcessor {
  /**
   * Get the button that opens the Save to Library menu when clicked.
   */
  static getLibraryMenuTriggerButton(familySlug, fontVariationId) {
    let card;
    if (familySlug) {
      card = document.querySelector('af-family-card[data-slug="' + familySlug + '"]');
      card = card || document.querySelector('.adobe-fonts-family-card[data-family-slug="' + familySlug + '"]');
    }
    if (fontVariationId) {
      card = card || document.querySelector('af-variation-card[data-id="' + fontVariationId + '"]');
      card = card || document.querySelector('.font-variation-card[data-font-variation-id="' + fontVariationId + '"]');
    }
    const saveLibraryIcon = card?.querySelector('af-save-library-icon');
    return saveLibraryIcon?.shadowRoot?.querySelector('#trigger_button');
  }

  /**
   * Get the data for a post login action from the server side, ensuring the action is deleted in
   * the process to avoid any complications with actions being rerun multiple times.
   *
   * @param {String} postLoginActionId
   * @return {Promise}
   */
  static getPostLoginActionData = (postLoginActionId) => {
    return del('/post_login_actions/' + postLoginActionId).then((response) => response.text()).then((text) => {
      if (text === '') {
        return {};
      }
      return JSON.parse(text);
    });
  }

  /**
   * Returns the id of the post the login action, if there is one.
   * @return {String}
   */
  static getPostLoginActionId = () => {
    return UrlService.getSearchParam(POST_LOGIN_ACTION_ID_URL_PARAM);
  }

  /**
   * Run this method once at page load time process the post login action if
   * there is one to process.
   */
  static handlePageLoad = () => {
    const postLoginActionId = this.getPostLoginActionId();
    if (!postLoginActionId) {
      return;
    }

    return this.getPostLoginActionData(postLoginActionId)
      .then(this._processPostLoginActionData)
      .finally(this._removePostLoginActionId);
  }

  static _openSaveToLibraryMenu(familySlug, fontVariationId) {
    const triggerButton = this.getLibraryMenuTriggerButton(familySlug, fontVariationId);
    if (!triggerButton) {
      return;
    }
    triggerButton.click();
  }

  /**
   * Open the Create New Library dialog when Save a copy button is clicked.
   */
  static clickSaveCopyButton() {
    const button = document.querySelector('#copy-library-trigger');
    button.click();
  }

  /**
   * Click the follow library button - we have to reach within the shadow DOM to correctly trigger the add / remove bookmark flow.
   */
  static async clickFollowLibraryButton() {
    const component = document.querySelector('[data-id="follow-library-button"]');
    if (!component) {
      return;
    }

    const button = component.shadowRoot.querySelector('sp-button');
    if (button) {
      try {
        await this.handleCheckBookmarks(component);
        if (!component.hasAttribute('followed')) {
          button.click();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  static handleCheckBookmarks(button) {
    checkBookmarks(button);
  }

  /**
   * Process the post login action data once it's been returned by the server.
   */
  static _processPostLoginActionData = (postLoginAction) => {
    UrlService.removeSearchParam(POST_LOGIN_ACTION_ID_URL_PARAM);

    if (postLoginAction.actionName === POST_LOGIN_ACTION_TYPE.ADD_TO_WEB_PROJECT) {
      const AddFontsToWebProjectService = service('AddFontsToWebProjectService');
      return AddFontsToWebProjectService.addFamilyToWebProject(
        postLoginAction.data.familySlug,
        postLoginAction.data.variationSlug);
    }

    if (postLoginAction.actionName === POST_LOGIN_ACTION_TYPE.OPEN_LIBRARY_MENU) {
      this._openSaveToLibraryMenu(postLoginAction.data.familySlug, postLoginAction.data.font_variation_id);
    }

    if (postLoginAction.actionName === POST_LOGIN_ACTION_TYPE.COPY_LIBRARY) {
      this.clickSaveCopyButton();
    }

    if (postLoginAction.actionName === POST_LOGIN_ACTION_TYPE.FOLLOW_LIBRARY) {
      this.clickFollowLibraryButton();
    }
  }

  /**
   * Removes the id of the post the login action from the URL.
   */
  static _removePostLoginActionId = () => {
    return UrlService.removeSearchParam(POST_LOGIN_ACTION_ID_URL_PARAM);
  }
}
